@tailwind base;
@tailwind components;
@tailwind utilities;

// frontend/src/assets/font/RNSSanz/RNSSanz-Black.otf
// frontend/src/assets/font/RNSSanz/RNSSanz-Bold.otf
// frontend/src/assets/font/RNSSanz/RNSSanz-ExtraBold.otf
// frontend/src/assets/font/RNSSanz/RNSSanz-Light.otf
// frontend/src/assets/font/RNSSanz/RNSSanz-Medium.otf
// frontend/src/assets/font/RNSSanz/RNSSanz-Normal.otf
// frontend/src/assets/font/RNSSanz/RNSSanz-SemiBold.otf

@font-face {
  font-family: 'regular';
  font-style: normal;
  font-weight: 400;
  src: url("./assets/font/RNSSanz/RNSSanz-Normal.otf") format("opentype");
}
@font-face {
  font-family: 'thin';
  font-weight: 400;
  src: url("./assets/font/RNSSanz/RNSSanz-Light.otf") format("opentype");
}
@font-face {
  font-family: 'light';
  font-weight: 400;
  src: url("./assets/font/RNSSanz/RNSSanz-Light.otf") format("opentype");
}
@font-face {
  font-family: 'bold';
  font-weight: 400;
  src: url("./assets/font/RNSSanz/RNSSanz-Bold.otf") format("opentype");
}
  html {
    scroll-behavior: smooth;
  }

  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  
  .no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  * {
    color: #333333;
  }